import styled from 'styled-components';
import {
    LayoutProps,
    ProbabilityCellProps,
    TutorialNoteProps,
} from "./interfaces";


export const PickominoLayout = styled.div<LayoutProps>`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: ${p => p.hideOverflow ? 'hidden' : 'auto'};;
  user-select: none;

  > div.lint {
    margin: auto;
    max-width: ${p => p.maxWidth ? p.maxWidth + 'px' : 'none'};
  }


  .rotate-right {
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out;

    &:hover {
      transform-origin: 50% 50%;
      transform: rotate(180deg);
    }
  }

  //&.slide-out {
  //
  //  @keyframes fadeOut {
  //    0% {
  //      opacity: 1.0;
  //    }
  //    100% {
  //      opacity: 0.3;
  //    }
  //  }
  //
  //  //.button.inactive {
  //  //  animation: fadeOut 1s ease-in-out;
  //  //}
  //
  //}

  .button {
    opacity: ${p => p.doOpaque ? 0.30 : 1.00};
    transition: transform 0.2s ease-in-out;

    &:not(.inactive) {
      opacity: 1.0;

      cursor: pointer;

      &:hover {
        transform: scale(1.2);
      }

      &:active {
        transform: scale(0.9);
      }

    }
  }

  .non-transparent {
    opacity: 1.0;
  }

  .brain {
    display: flex;
    justify-content: center;
    margin: 1em;
    font-size: 1.1em;
  }

  .draw-attention:not(.inactive) {
    animation: drawAttention 1s ease-in-out infinite alternate;
  }

  .super-draw-attention:not(.inactive) {
    animation: superDrawAttention 1s ease-in-out infinite alternate;
  }

  @keyframes drawAttention {
    0% {
      transform: scale(0.90);
    }
    100% {
      transform: scale(1.1);
    }
  }
  @keyframes superDrawAttention {
    0% {
      transform: scale(0.85);
    }
    100% {
      transform: scale(1.2);
    }
  }

  span.tooltip {
    cursor: help;
    height: 1.1em;
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted ${({theme}) => theme.semiforeground};
  }

  span.tooltip .tooltip-text {
    font-weight: normal;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    visibility: hidden;
    width: 240px;
    background-color: ${({theme}) => theme.semiforeground};
    color: #fff;
    border-radius: 6px;
    padding: 12px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  span.tooltip:hover .tooltip-text {
    visibility: visible;
    z-index: 999;
  }
`

export const StyledMenu = styled.div`
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  //color: white;
  display: flex;
  justify-content: center;
  padding-top: 35px;
  z-index: 1;
  //backdrop-filter: blur(4px);
  //background-color: rgba(194, 223, 233, 80%);
  background-color: #c2dfdf;

  &.slide-out {
    @keyframes slideOut {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(100vh);
      }
    }
    animation: slideOut 1s ease-in-out;
  }

  img {
    width: 200px;
    height: 185px;
  }

  h1 {
    width: 200px;
    color: black;
    font-size: 32px;
  }

  p {
    color: black;
    display: inline;
    line-height: 3em;
    width: 0;
    font-size: 24px;

    &:hover {
      text-shadow: 0 0 3px white;
    }
  }

  div.lang-container {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;

    svg {
      margin: 10px;
      max-width: 40px;
      border-radius: 6px;
    }
  }
`;

export const StyledProbabilityCell = styled.td.attrs<ProbabilityCellProps>(props => ({
    style: {
        backgroundColor: props.probability > 0 ? `hsl(${props.maxProba ? props.probability / props.maxProba * 100 : props.probability}, 90%, 60%)` : 'none',
        color: 'black',
    },
}))``;

export const StyledPlayerHeader = styled.div`
  height: 200px;
  display: grid;
  grid-template-columns: 50px repeat(6, 1fr);
`;

export const StyledDiceForm = styled.div`
  display: grid;
  grid-template-columns: 50px repeat(6, 1fr);
  justify-items: center;
  align-items: center;
  user-select: none;

  input {
    width: 100px;
    text-align: center;
    font-weight: bold;
    border: none;
  }

  h1 {
    margin: 0;

  }

  h1.shuffle {
    height: 1em;

    &:not(.inactive) {
      color: #2251ff;
    }
  }

  h1.inactive {
    opacity: 0.3;
  }

  h1.bot-pick {
    text-shadow: 0 0px 20px #2251ff;
  }

  h1.no-option {
    text-shadow: 0 0px 20px #e61a46;
  }

  div {
    margin: auto;
    text-align: center;
  }

  button {
    border: none;
    border-radius: 6px;
    @media (max-width: 510px) {
      border: 1px solid ${({theme}) => theme.body};
    }
    @media (min-width: 510px) {
      margin: 1px;
    }
    @media (min-width: 610px) {
      margin: 5px;
    }
    @media (hover: hover) {
      :hover {
        background-color: #d7d7d7;
        cursor: pointer;
      }
    }
  }

  div.field-container {
    width: 100%;
  }

  div.button-container {
    position: relative;

    button {
      padding: 0;
      font-size: 30px;
      width: 50%;
      max-width: 38px;
    }
  }
`;

export const StyledTutorialNote = styled.div<TutorialNoteProps>`
  position: relative;
  opacity: 1.0;
  z-index: 1;
  font-weight: normal;
  font-size: 18px;
  font-family: 'Fira Sans', sans-serif;

  ol {
    padding-left: 2em;
  }

  > span {
    text-align: ${p => p.ta ? p.ta : 'center'};
    position: absolute;
    width: ${p => p.width ? p.width + 'px' : '240px'};
    z-index: 100;
    background: ${({theme}) => theme.semiforeground};
    color: #fff;
    border-radius: 6px;
    left: ${p => p.alignRight ? -200 + (p.ha ? p.ha : 0) + 'px' : (p.ha ? p.ha + 5 + 'px' : '5px')};
    top: ${p => p.alignBottom ?  -100 + (p.va ? p.va : 0) + 'px' : (p.va ? p.va - 12 + 'px' : '-12px')};
    height: ${p => p.alignBottom ? 90 + "px" : "auto"};
    padding: ${p => p.alignBottom ? "6px 12px" : "12px"};
    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: ${p => p.alignBottom ? "0px" : "10px"} solid ${({theme}) => theme.semiforeground};
      border-top: ${p => p.alignBottom ? "10px" : "0px"} solid ${({theme}) => theme.semiforeground};
      left: ${p => p.alignRight ? '200px' : '12px'};
      top: ${p => p.alignBottom ? '90px' : '-10px'};
    }
  }

  @keyframes quickAppearScale {
    0% {
      transform: scale(1.5);
      opacity: 0.0;
    }
    25% {
      transform: scale(1.5);
      opacity: 0.0;
    }
    75% {
      transform: scale(1.5);
      opacity: 1.0;
    }
    100% {
      transform: scale(1);
      opacity: 1.0;
    }
  }
  //&.anim {
  //  animation: quickAppearScale 1.333s ease-in-out forwards;
  //}
`;

export const StyledTable = styled.div`
  width: 100%;
  display: flex;

  div.table {
    justify-content: center;
    max-width: 500px;
    width: 100%;
    margin: auto;
    padding: 5px;
    display: grid;
    gap: 10px;
    @media (max-width: 490px) {
      gap: 5px;
    }
    grid-template-columns: repeat(auto-fill, 50px);

    @media (min-width: 390px) {
      grid-template-columns: repeat(7, 1fr);
    }

    @media (min-width: 445px) {
      grid-template-columns: repeat(8, 50px);
    }
  }

`;

export const StyledPlayerFrame = styled.div`
  cursor: default;
  user-select: none;
  text-align: center;
  position: relative;

  &.active {
    h4 {
      background-color: #2251ff;
      color: white;
      width: 100%;
      max-width: 100px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 6px;
    }
  }

  h4:focus {
    outline: none;
  }
`;

export const TilePairContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 80px;
`;

export const StyledTileWrapper = styled.div`
  border-radius: 6px;
  
  // Prevent shine through from quickOpacity animation 
  background-color: ${({theme}) => theme.body};

  @keyframes quickAppearScale {
    0% {
      transform: scale(1.5);
      opacity: 0.0;
    }
    25% {
      transform: scale(1.5);
      opacity: 0.0;
    }
    75% {
      transform: scale(1.5);
      opacity: 1.0;
    }
    100% {
      transform: scale(1);
      opacity: 1.0;
    }
  }

  @keyframes quickDisappearScale {
    0% {
      transform: scale(1.0);
      opacity: 1.0;
    }
    25% {
      transform: scale(1.5);
      opacity: 1.0;
    }
    75% {
      transform: scale(1.5);
      opacity: 0.0;
    }
    100% {
      transform: scale(1.5);
      opacity: 0.0;
    }
  }

  @keyframes quickFadeAway {
    0% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.0;
    }
  }

  @keyframes quickOpacity {
    0% {
      opacity: 1.0;
    }
    66% {
      opacity: 1.0;
    }
    100% {
      opacity: 0.30;
    }
  }

  &.table-add {
    animation: quickAppearScale 1.333s ease-in-out forwards;

    .button {
      box-shadow: 0 0px 20px #2251ff;
      animation: quickOpacity 2s ease-in-out forwards;
    }
  }

  &.table-remove {
    animation: quickDisappearScale 1.333s ease-in-out forwards;

    .button {
      opacity: 1.0;
    }
  }

  &.player-add {
    animation: quickAppearScale 1.333s ease-in-out forwards;

    .button {
      box-shadow: 0 0px 20px #e61a46;
      animation: quickOpacity 2s ease-in-out forwards;
    }
  }

  &.player-remove {
    animation: quickDisappearScale 1.333s ease-in-out forwards;

    .button {
      opacity: 1.0;
    }
  }

  &.table-lost {
    animation: quickFadeAway 1.333s ease-in-out forwards;

    .button {
      opacity: 0.3;
    }
  }


`;

export const StyledTile = styled.div`
  background-color: #ebe5cf;
  color: #2251ff;
  width: 50px;
  height: 80px;
  margin: auto;
  text-align: center;
  border-style: outset;
  border-radius: 6px;

  img {
    width: 80%;
  }

  h2 {
    margin: 0;
  }
`;

export const StyleEndScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  //backdrop-filter: blur(4px);
  //background-color: rgba(194, 223, 233, 80%);
  background-color: #c2dfdf;
  z-index: 1;
  
  >div.lint {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  h1 {
    color: black;
    display: flex;
    justify-content: space-between;
  }

  @keyframes slideOut {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(0);
    }
  }

  &.slide-out {
    animation: slideOut 1s ease-in-out reverse;
  }

  &.slide-in {
    animation: slideOut 1s ease-in-out;
  }

  & > div {
    max-width: 440px;
    padding-top: 4em;
    margin: auto;
    //background-color: #bfdddb;
    border-radius: 6px;

    div.score-wrapper {
      max-width: 440px;

      div.player-info {
        position: relative;
        display: grid;
        grid-template-columns: 40px auto 25px;

        > h2 {
          color: black;
          margin: .7em 0 .1em 0;
        }
      }

      div.tile-info {
        width: 100%;
        //position: absolute;
        display: grid;
        grid-auto-rows: 80px;
        grid-template-columns: repeat(auto-fill, 50px);
        grid-gap: 10px;
      }
    }
  }
`;

export const StyledDice = styled.div`
  background: linear-gradient(135deg, #fffef8, #C0BCB8);
  width: 80%;
  max-width: 80px;
  border-radius: 10%;

  div.worm-face {
    padding: 10%;
    background-color: #E8E5DE;
    border-radius: 40%;

    img {
      width: 74%;
    }

    @media (min-width: 400px) {
      img {
        width: 79.68%;
      }
    }
  }

  div.pip-face {
    padding: 22%;
    background-color: #E8E5DE;
    border-radius: 40%;
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
    grid-gap: 3px;
    grid-template-areas:
        "a . c"
        "e g f"
        "d . b";

    div.pip {
      background-color: #2251ff;
      //box-shadow:  inset 1px 1px 0px blue;
      width: 100%;
      padding-top: 100%;
      border-radius: 50%;
    }

    .pip:nth-child(2) {
      grid-area: b;
    }

    .pip:nth-child(3) {
      grid-area: c;
    }

    .pip:nth-child(4) {
      grid-area: d;
    }

    .pip:nth-child(5) {
      grid-area: e;
    }

    .pip:nth-child(6) {
      grid-area: f;
    }

    /* This selects the last pip of odd-valued dice (1, 3, 5) and positions the pip in the center */

    .pip:nth-child(odd):last-child {
      grid-area: g;
    }

  }
`

export const StyledDiceTable = styled.table`
  margin-top: 1em;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  table-layout: fixed;
  width: 100%;
  display: table;

  tr, td {
    padding: 0;
    border: 0;

    .head {
      width: 30px;
    }
  }

  td:not(.head):not(:empty):after {
    @media (min-width: 450px) {
      content: "%";
    }
  }

  tr:first-child td:first-child {
    border-radius: 10px 0 0 0;
  }

  // Top left
  tr:first-child td:last-child {
    border-radius: 0 10px 0 0;
  }

  // Top right
  tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
  }

  // Bottom left
  tr:last-child td:last-child {
    border-radius: 0 0 10px 0;
  }

  // Bottom right
}
`