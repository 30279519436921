import React, {useEffect, useRef, useState} from "react";
import Layout from '../../../src/components/layout';
import SEO from '../../../src/components/seo';
import {DE, GB, NL, FR, JP, CN, ES} from 'country-flag-icons/react/3x2'

import {
    FaMinus,
    FaPlus,
    FaRotateRight,
    FaShuffle,
} from 'react-icons/fa6';

import {
    DiceProps,
    EndScreenProps,
    HeaderProps,
    PairProps,
    PickominoProps,
    PlayerTileProps,
    TableProps,
    TileProps,
    GameOverStatus,
    MenuStatus,
} from "./interfaces";

import {
    PickominoLayout,
    StyledDice,
    StyledDiceForm,
    StyledMenu,
    StyledPlayerFrame,
    StyledPlayerHeader,
    StyledTable,
    StyledTile,
    StyledTileWrapper,
    StyleEndScreen,
    TilePairContainer,
} from "./style";

import {
    ProbabilityTable,
} from "./components";

import {
    allTiles,
    dice,
    Player,
    players,
    Tile,
    tileTable,
    transferRecord,
    tutorial,
} from "./objects";

import {
    changePlayer,
    countActivePlayers,
    findMaxProbability,
    getNormallyDistributedRandomNumber,
    pickTile,
    stealTile,
    calculateProbabilities,
} from "./methods";

import {fetchJsonData} from "../../../src/components/utils";

import {
    botDecideTime,
    diePickWaitTime,
    reviewTime,
    rollAnimTimeMean,
    rollAnimTimeSD,
    transferTime,
    menuTransitionTime,
} from "./parameters";

let targetTileValue: number = 25;
let targetDieIndex: number = 5;
let rollAnimTime: number;


const PickominoPage = ({pageContext}: { pageContext: PickominoProps }) => {
    // U S E   S T A T E S
    // Read source data
    const {minimal_file, exactly_file, wormSvgFile, worm1, worm2, worm3, worm4, preview} = pageContext;

    // Set language code
    const [langCode, setLangCode] = useState(preview.name.split("_").pop());
    const langCodeRef = useRef(langCode);
    const text = require(`../lang/${langCode}.json`)

    // Set tutorial language
    const [tutorialStage, setTutorialStage] = useState(0)
    tutorial.set(text.Tutorial)

    // Set the current player
    const [playersState, setPlayersState] = useState(players);
    const [currentPlayer, setCurrentPlayer] = useState(players[0]);
    const prevPlayerRef = useRef(currentPlayer);

    // Probability map collections
    const [minimalData, setMinimalData] = useState(null);
    const [exactlyData, setExactlyData] = useState(null);

    // Dice state collections
    const [currentCollection, setCurrentCollection] = useState(dice.collected);
    const [rolledDice, setRolledDice] = useState(dice.rolled);
    const [rollingCompleted, setRollingCompleted] = useState(true);

    // State of the table that is returned
    const [probabilities, setProbabilities] = useState({});
    const [showPobas, setShowProbas] = useState(false);

    // Game State
    const gameOver = useRef<GameOverStatus>('ongoing');
    const menuScreen = useRef<MenuStatus>('up');
    const controlEnabled = useRef(true);
    // controlEnabled = menuScreen != "slide-out" && gameOver != "slide-in" && gameOver != "slide-out"

    const allowPlayerRemove = rollingCompleted && (!players || countActivePlayers(players) > 2);
    const allowPlayerAdd = rollingCompleted && (!players || countActivePlayers(players) < 6);
    const inSlideState = (menuScreen.current === 'slide-out' || gameOver.current === 'slide-out' || gameOver.current === 'slide-in')

    // U S E   E F F E C T S

    // Asynchronously fetch probability data if not set yet upon render
    useEffect(() => {
        if (exactlyData == null && minimalData == null) {
            // Fetch data for probabilities_file1 and probabilities_file2 in parallel
            Promise.all([
                fetchJsonData(exactly_file, setExactlyData),
                fetchJsonData(minimal_file, setMinimalData),
            ]).catch(error => console.error('Error loading files:', error));
        }
    }, []);

    useEffect(() => {
        // Calculate probabilities when the game boots, so the bot knows what to play for
        if (exactlyData !== null && minimalData !== null) {
            doProba()
        }
    }, [exactlyData, minimalData]);

    // Change out the language code
    useEffect(() => {
        text.names.map((name: string, index: number) => {
            players[index].name = name;
        });
        if (langCodeRef.current !== langCode) {
            langCodeRef.current = langCode;
        }
    }, [langCode]);

    // Manage the progression of the tutorial
    useEffect(() => {
        // Register the onClick tutorial progress event
        if (controlEnabled.current) {
            document.body.addEventListener('click', handleProgressTutorial);
        }

        // Initiate the dice roll during the tutorial
        if (tutorial.isAt("playround2") && !currentPlayer.is_human) {
            handleDiceRoll();
        }

        // Initiate the dice roll during the tutorial
        if (tutorial.isAt("brain")) {
            setShowProbas(true);
        }

        // Initiate the dice roll during the tutorial
        if (tutorial.isAt("outro")) {
            setShowProbas(false);
        }

        // Initiate play during the tutorial
        if (tutorial.changeAllowance(tutorialPlayIsAllowed()) && tutorial.allowsPlay) {
            // Pick a die from the example roll
            handleDiePick(targetDieIndex);
        }

        // Add current dice score to the tutorial message
        if (tutorial.isAt("dicescore1")) {
            const scoreString = dice.scoreString();
            if (scoreString !== undefined) {
                tutorial.addText("dicescore2", scoreString);
                setTutorialStage(tutorial.progress())
            } else {
                // Skip if there was no appropriate score string to present
                setTutorialStage(tutorial.progress(false, 2))
            }
        }

        // Clean up after render
        return () => {
            document.body.removeEventListener('click', handleProgressTutorial);
        };
    }, [tutorialStage]);

    // Clear the transfer record after the next turn has started
    useEffect(() => {
        transferRecord.clear();
    }, [playersState]);

    // If the player has any dice, decide to reroll or pick a tile
    useEffect(() => {
        if (!dice.isEmpty()) {
            continueTurn()
        }
    }, [currentCollection])

    // Roll the dice when the current player switches
    useEffect(() => {
        if (prevPlayerRef.current !== currentPlayer) {
            prevPlayerRef.current = currentPlayer;
            handleDiceRoll();
        }
    }, [currentPlayer])

    useEffect(() => {
        if (tutorialStage === 0 || gameOver.current === "over" || !controlEnabled.current) {
            return;
        }
        // Roll, if it has not completed yet
        if (!rollingCompleted) {
            // This sets the dice state during rolling
            const diceRollInterval = setInterval(() => {
                setRolledDice(dice.rolling());
            }, 100);

            // This happens after the rolling is done
            const diceRollTimeout = setTimeout(() => {
                // We are done with the rolling timer
                clearInterval(diceRollInterval);
                // This line sets the dice state that is finally shown
                setRolledDice(dice.rollDice());
                // This state inhibits actions during the dice rolling
                setRollingCompleted(true);
            }, rollAnimTime);

            return () => {
                clearTimeout(diceRollTimeout);
            };
        }

        // Rolling has completed, computes probability and preferences
        if (!doProba() || turnOver()) {
            handleDoneFor();
            return;
        }

        // When the play state changes during the tutorial, continue to the next note
        if (!tutorial.finished()) {
            tutorial.changeAllowance(tutorialPlayIsAllowed());
            if (!dice.noOptions() || currentPlayer.is_human) {
                setTutorialStage(tutorial.progress())
            }
        }

        // Let a human decide
        if (currentPlayer.is_human) {
            return;
        }

        // Don't go to pick tile during tutorial
        if (!tutorial.allowsPlay) {
            return;
        }

        // Make the bot decide
        const waitForDiePick = setTimeout(() => {
            if (!dice.rolled_dice()) {
                // Somehow, the state of a player is sometimes reset after the completion of a dice roll.
                // This is probably a concurrency issue, where states are updated before a rerender has finished.
                // But what about it, we just roll again.
                handleDiceRoll();
                return;
            }

            if (targetDieIndex === undefined) {
                // There are no ways to win the round, just pick the highest die
                const availableDie = [5, 4, 3, 2, 1, 0].findIndex(index => dice.isPickable(index));
                if (availableDie === -1) {
                    // Bot somehow cant find a die, impossible, but go to next player
                    handleDoneFor();
                    return;
                }
                targetDieIndex = availableDie;
            }
            handleDiePick(targetDieIndex);
        }, diePickWaitTime);
        return () => {
            clearTimeout(waitForDiePick);
        }
    }, [rollingCompleted]);


    // C O M P O N E N T S

    const DieComponent: React.FunctionComponent<DiceProps> = ({value: index, src}) => {
        // Check if this die is a worm, to change styling rules
        const is_worm = index === 5 && src;
        const isNonTransparent = (tutorial.isAt("worm face") && is_worm);
        // Apply to button class for interactive styling, and inactive if not rolled or collected
        const sup_class_name = `button draw-attention${!rollingCompleted || !dice.isPickable(index) ? " inactive" : ""}${isNonTransparent ? " non-transparent" : ""}`;
        // Predefine a content object
        let content;

        // Set the content based on object parameters.tsx
        if (is_worm) {
            content = <img src={src}/>;
        } else {
            const Pip = () => <div className="pip"/>;
            content = Number.isInteger(index)
                ? Array(index + 1)
                    .fill(0)
                    .map((_, i) => <Pip key={i}/>)
                : null;
        }
        // Return the content
        return (
            <StyledDice
                className={sup_class_name}
                onClick={rollingCompleted && dice.isPickable(index) && currentPlayer.is_human ? () => handleDiePick(index) : undefined}
            >
                <div className={is_worm ? "worm-face" : "pip-face"}>
                    {content}
                </div>
            </StyledDice>
        );
    };

    const PlayerHeader: React.FunctionComponent<HeaderProps> = ({players}) => {
        return (
            <StyledPlayerHeader>
                <StyledPlayerFrame>
                    <h2
                        className={`button${!allowPlayerAdd ? " inactive" : ""}`}
                        onClick={allowPlayerAdd ? () => handleChangePlayer(true) : undefined}
                    ><FaPlus/></h2>
                    <h2
                        className={`button${!allowPlayerRemove ? " inactive" : ""}`}
                        onClick={allowPlayerRemove ? () => handleChangePlayer(false) : undefined}
                    ><FaMinus/></h2>
                    {tutorial.Note({
                        stage: ["nplayers"]
                    })}
                    {tutorial.Note({
                        stage: ["add player"],
                        width: 200
                    })}
                </StyledPlayerFrame>
                {players && players.map((player, index) => (<PlayerFrame key={index} player={player}/>))}
            </StyledPlayerHeader>
        );
    };

    const PlayerFrame: React.FunctionComponent<PlayerTileProps> = ({player}) => {
        if (!player.is_active) {
            return (<StyledPlayerFrame/>);
        }

        const [face, setFace] = useState(player.face);


        let staticTile: Tile | undefined;
        let changedTile: Tile | undefined;

        // We do not render any tiles while controlEnabled is false = start of new game
        if (controlEnabled.current) {
            if (transferRecord.fromPlayer === player) {
                // The tile is stolen from this player, so the tile is missing
                staticTile = player.lastTile(-1)
                changedTile = transferRecord.tile;
            } else if (transferRecord.toPlayer === player) {
                // The tile is added, so it is already present at moment of animation
                staticTile = player.lastTile(-2)
                changedTile = transferRecord.tile;
            } else {
                staticTile = player.lastTile(-1);
            }
        }
        const handleNameChange = (event: React.ChangeEvent<HTMLHeadingElement>) => {
            // Get the trimmed text content from the event target
            const newName = event.target.innerText.trim();

            // Ensure the name doesn't exceed 10 characters
            const truncatedName = newName.slice(0, 10);

            // Update the player's name with the truncated name
            player.bot_name = truncatedName;
            player.name = truncatedName;
        };

        const keyPressEvent = (e: any) => {
            // Get the current text content length
            const currentLength = e.target.innerText.length;

            // Prevent further typing if the maximum length is reached and the key is not backspace
            if (currentLength >= 10 && e.keyCode !== 8) {
                e.preventDefault();
            }
        };

        const toggleHuman = () => {
            setFace(player.toggleHuman());
        };

        let isFirstHuman = false;
        let isFirstAI = false;
        if (!tutorial.finished()) {
            const firstHuman = players.find(p => p.is_human);
            const firstAI = players.find(p => !p.is_human);
            if (firstHuman && firstHuman.uid && firstHuman.uid === player.uid) {
                isFirstHuman = true;
            } else if (firstAI && firstAI.uid && firstAI.uid === player.uid) {
                isFirstAI = true;
            }
        }

        return (
            <StyledPlayerFrame className={player === currentPlayer ? "active" : "inactive"}>
                <h2 onClick={toggleHuman} className={"button"}>{face}</h2>
                {isFirstAI && tutorial.Note({stage: ["outro", "fun", "aipick", "dicescore2"]})}
                {isFirstAI && tutorial.Note({stage: ["toggle ai"], width: 200})}
                {isFirstAI && tutorial.Note({stage: ["outline"], width: 250, ta: "left"})}
                {player.is_human ? (
                    <>
                        <h4
                            contentEditable={player.is_human ? "true" : "false"}
                            onKeyDown={keyPressEvent}
                            suppressContentEditableWarning={true}
                            onInput={handleNameChange}
                            className={player.is_human ? "player-name" : ""}
                        >
                            {player.name}
                        </h4>
                        {isFirstHuman && tutorial.Note({
                            stage: ["name"],
                            width: 200
                        })}
                        {isFirstHuman && tutorial.Note({
                            stage: ["stack"],
                            va: 50
                        })}
                    </>
                ) : (
                    <><h4>{player.name}</h4>
                        {isFirstAI && tutorial.Note({
                            stage: ["steal", "steal exact"],
                            va: 50
                        })}</>)}
                <TilePair
                    staticTile={staticTile}
                    changedTile={changedTile}
                    owner={player}
                />
            </StyledPlayerFrame>
        );
    };

    const TileObject: React.FunctionComponent<TileProps> = (
        {tile, owner = undefined, forceActive = false, style = undefined,}
    ) => {
        // If no owner is provided the tile is visible, but not usable. E.g., end screen
        let method = undefined;
        // We can only pick tiles up if there are no rolled dice on the table
        if (!dice.rolled_dice()) {
            // If an owner is provided,
            if (owner !== undefined) {
                // And the tile is owned by the table,
                if (owner === tileTable) {
                    // The score of the collected dice should be >= to the value of the tile
                    if (dice.score() >= tile.value) {
                        // We then supply the TilePick method
                        method = () => handleTilePick(tile);
                    }
                    // If the tile is owned by anyone else than the table, this is assumed to be a player,
                    // The score should be exactly equal to the tile value. Also, you cannot steal your own tile.
                } else if (dice.score() === tile.value && owner !== currentPlayer) {
                    // Supply the TileSteal method
                    method = () => handleTileSteal(owner);
                }
            }
        }

        // Set animation
        let tileAnimation = "";
        if (transferRecord.tile && transferRecord.fromPlayer && transferRecord.tile.value === tile.value) {
            const animStyle = transferRecord.fromPlayer.is_table ? "table" : "player";
            const animDirection = transferRecord.fromPlayer === owner ? "remove" : "add";
            tileAnimation = animStyle + "-" + animDirection;
        } else if (transferRecord.flippedTile && transferRecord.flippedTile.value === tile.value) {
            tileAnimation = "table-lost";
        }

        return (
            <StyledTileWrapper className={tileAnimation} style={style}>
                <StyledTile
                    className={`button ${gameOver.current != "ongoing" ? "" : "draw-attention"}${forceActive || method ? "" : " inactive"}`}
                    onClick={currentPlayer.is_human ? method : undefined}>
                    <h2>{tile.value}</h2>
                    {tile.worth === 1 && <img src={worm1} alt="Worm 1"/>}
                    {tile.worth === 2 && <img src={worm2} alt="Worm 2"/>}
                    {tile.worth === 3 && <img src={worm3} alt="Worm 3"/>}
                    {tile.worth === 4 && <img src={worm4} alt="Worm 4"/>}
                </StyledTile>
                {tile.value === 21 && tutorial.Note({
                    stage: ["objective", "endgame", "turn objective", "tile collect", "tile pick"],
                    va: 20
                })}
                {tile.value === 26 && tutorial.Note({stage: ["tile sum"], va: 20, alignRight: true})}
            </StyledTileWrapper>
        );
    }

    const TilePair: React.FunctionComponent<PairProps> = ({staticTile, changedTile, owner}) => {
        return (
            <TilePairContainer>
                {staticTile && owner ? (
                    <TileObject
                        tile={staticTile}
                        owner={owner}
                    />
                ) : (
                    <div/>
                )}
                {changedTile ? (
                    <TileObject
                        style={{position: "absolute"}}
                        tile={changedTile}
                        owner={owner}
                    />
                ) : (
                    <div/>
                )}
            </TilePairContainer>
        );
    };

    const TileTable: React.FunctionComponent<TableProps> = ({table}) => {
        return (
            <StyledTable>
                <div className={"table"}>
                    {allTiles.map((tile, index) => {
                        const tileIndex = table.getTileIndex(tile);
                        let staticTile: Tile | undefined;
                        if (tileIndex !== undefined) {
                            staticTile = tile;
                        }
                        let changedTile: Tile | undefined;

                        // The changedTile tile is a tile animation that persists for only one render.
                        // In the TileTable context, we only render a changedTile if
                        // 1. it exists in the transferRecord, so it was recently added to the table,
                        // 2. if the table has anything to do with the tile and
                        // 3. if the tile added is not equal to the tile flipped.
                        if (
                            transferRecord.tile && transferRecord.tile.value === tile.value &&
                            (transferRecord.fromPlayer == table || transferRecord.toPlayer == table) &&
                            !(transferRecord.toPlayer == table && transferRecord.flippedTile === undefined)) {
                            changedTile = tile;
                        } else if (transferRecord.flippedTile && transferRecord.flippedTile.value === tile.value) {
                            changedTile = tile;
                        }
                        if (staticTile || changedTile) {
                            return (
                                <TilePair
                                    key={index}
                                    owner={tileTable}
                                    staticTile={staticTile}
                                    changedTile={changedTile}
                                />
                            );
                        } else {
                            return <TilePairContainer key={index}/>;
                        }
                    })}
                </div>
            </StyledTable>
        );
    };

    const Menu = () => {
        return (
            <StyledMenu className={menuScreen.current}>
                <div>
                    <img src={worm4} alt="Picomino icon"/>
                    <h1>{text.name}</h1>
                    <p className={'button'}
                       onClick={handleSkipTutorial}>{text.play}</p>
                    <br/>
                    <p className={'button'} onClick={handleStartTutorial}>{text.tutorial}</p>
                    <div className={'lang-container'}>
                        {langCode !== 'nl' && <NL className={"button"} onClick={() => handleLangChance('nl')}/>}
                        {langCode !== 'en' && <GB className={"button"} onClick={() => handleLangChance('en')}/>}
                        {langCode !== 'de' && <DE className={"button"} onClick={() => handleLangChance('de')}/>}
                        {langCode !== 'fr' && <FR className={"button"} onClick={() => handleLangChance('fr')}/>}
                        {langCode !== 'es' && <ES className={"button"} onClick={() => handleLangChance('es')}/>}
                        {langCode !== 'jp' && <JP className={"button"} onClick={() => handleLangChance('jp')}/>}
                        {langCode !== 'cn' && <CN className={"button"} onClick={() => handleLangChance('cn')}/>}
                    </div>
                </div>
            </StyledMenu>
        );
    };

    const EndScreen: React.FunctionComponent<EndScreenProps> = ({players}) => {
        // Filter active players and sort them by score
        const activePlayers = players.filter(player => player.is_active);
        const sortedPlayers = activePlayers.sort((a, b) => b.score() - a.score());

        return (
            <StyleEndScreen className={gameOver.current}>
                <div className={"lint"}>
                    <h1>
                        <span>Scores</span>
                        <FaRotateRight onClick={restartGame} className={'rotate-right'}/>
                    </h1>
                    <div>
                        {sortedPlayers.map((player, index) => (
                            <div className={'score-wrapper'} key={index}>
                                <div className={"player-info"}>
                                    <h2>{player.face}</h2>
                                    <h2>{player.name}</h2>
                                    <h2>{player.score()}</h2>
                                </div>
                                <div className={"tile-info"}>{player.tiles.map((tile, tileIndex) => (
                                    <TileObject
                                        key={tileIndex}
                                        tile={tile}
                                        forceActive={true}
                                    />
                                ))}
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            </StyleEndScreen>
        );
    };

    // M E T H O D S

    const maybeEndGame = (): boolean => {
        if (tileTable.tiles.length === 0) {
            endGameSlideIn()
        }
        return (gameOver.current != 'ongoing');
    }

    const restartGame = () => {
        // Reset dice states
        dice.reset_collected();
        dice.reset_rolled();
        tileTable.init_tiles();
        setRollingCompleted(true);
        setCurrentCollection(dice.collected)
        setRolledDice(dice.rolled)
        tutorial.skip()
        setTutorialStage(-1)

        // Reset player states
        tileTable.init_tiles();

        // Set game state
        // setGameOver("screen-down");
        setShowProbas(false);
        endGameSlideOut();
    }

    const continueTurn = () => {
        // Handle cases where the player has nothing to choose
        if (dice.noOptions(currentPlayer)) {
            handleDiceRoll();
            return;
        }
        // Show the position for continuing play
        dice.reset_rolled();
        setRolledDice(dice.rolled);
        doProba();

        // Only bots outside the tutorial can pick tiles
        if (currentPlayer.is_human || tutorialStage != -1) {
            return;
        }

        // Wait some time before picking a tile
        const tilePickTimeout = setTimeout(() => {
            // We are able to pick up our target tile
            if (targetTileValue && targetTileValue > dice.score()) {
                // We are not (yet) able to pick up our target die, roll again
                handleDiceRoll();
                return;
            }
            // We are able to get our target tile, find it...
            // Check table tiles
            for (const tile of tileTable.tiles) {
                if (tile.value === targetTileValue) {
                    handleTilePick(tile);
                    return;
                }
            }
            // Check player tiles to steal
            for (const player of players) {
                if (currentPlayer && currentPlayer === player) {
                    continue;
                }
                const lastTile = player.lastTile();
                if (lastTile && lastTile.value === targetTileValue) {
                    handleTileSteal(player);
                    return;
                }
            }
        }, botDecideTime);
        return () => clearTimeout(tilePickTimeout);
    }

    const turnOver = () => {
        // The player is done for either when
        // 1. isBust: no dice rolled can be picked up
        // 2. noOptions && no freeDice: The player can't roll and has no options
        return dice.isBust() || (dice.noOptions(currentPlayer) && !dice.freeDice());
    }

    const doProba = (): boolean => {
        if (minimalData === null || exactlyData === null) {
            return false;
        }

        const newProbabilities = calculateProbabilities(dice.collected, dice.rolled, currentPlayer, minimalData, exactlyData);
        setProbabilities(newProbabilities);

        const {
            targetTileValue: newTargetTileValue,
            targetDieIndex: newTargetDieIndex
        } = findMaxProbability(newProbabilities);
        if (newTargetTileValue === undefined || newTargetDieIndex === undefined) {
            return false;
        }

        targetTileValue = newTargetTileValue;
        targetDieIndex = newTargetDieIndex
        return true;
    };

    const tutorialPlayIsAllowed = (): boolean => {
        // During the tutorial the bot rolls dice.
        // This function checks if the play should be continued.
        return tutorial.finished() ||
            !currentPlayer.is_human &&
            tutorial.isAt("aipick") &&
            dice.noOptions(currentPlayer) &&
            !dice.isBust();
    };


    // H A N D L E   W R A P P E R S
    const toggleProbabilityTable = (force=false) => {
        if (tutorial.finished() || force) {
            setShowProbas(!showPobas)
        }
    }

    const handleProgressTutorial = () => {
        if (controlEnabled) {
            setTutorialStage(tutorial.progress());
        }
    };

    const handleTilePick = (tile: Tile) => {
        if (pickTile(tileTable, currentPlayer, tile)) {
            setPlayersState([...players])
        }
        // Finish the turn
        endTurn();
    }

    const handleTileSteal = (victim: Player) => {
        if (stealTile(victim, currentPlayer, false)) {
            setPlayersState([...players])
        }
        endTurn()
    }

    const handleSkipTutorial = () => {
        setTutorialStage(tutorial.skip());
        players[0].name = text.you;
        players[0].toggleHuman();
        slideMenuOut();
    }

    const slideMenuOut = () => {
        menuScreen.current = "slide-out";
        controlEnabled.current = false;
        const menuTransitionTimeout = setTimeout(() => {
            controlEnabled.current = true;
            menuScreen.current = 'down';
            setTutorialStage(tutorial.progress(true))
            // Enforce rerender regardless of tutorial state chance
            setPlayersState([...players])
        }, menuTransitionTime);
        return () => {
            clearTimeout(menuTransitionTimeout);
        };
    }

    const endGameSlideIn = () => {
        gameOver.current = "slide-in";
        setPlayersState([...players])
        controlEnabled.current = false;
        const gameOverTransitionTimeout = setTimeout(() => {
            controlEnabled.current = true;
            gameOver.current = "over";
            setPlayersState([...players])
        }, menuTransitionTime);
        return () => {
            clearTimeout(gameOverTransitionTimeout);
        };
    }

    const endGameSlideOut = () => {
        gameOver.current = "slide-out";
        controlEnabled.current = false;
        const gameOverTransitionTimeout = setTimeout(() => {
            controlEnabled.current = true;
            gameOver.current = "ongoing";
            players.forEach(player => {
                player.init_tiles();
            });
            setPlayersState([...players])
            setCurrentPlayer(players[0])
            if (!currentPlayer.is_human) {
                handleDiceRoll()
            }
        }, menuTransitionTime);
        return () => {
            clearTimeout(gameOverTransitionTimeout);
        };
    }

    const handleStartTutorial = () => {
        players[1].name = text.you;
        players[1].toggleHuman();
        setTutorialStage(tutorial.progress(true))
        slideMenuOut();
    }

    const handleChangePlayer = (do_add: boolean = true) => {
        setPlayersState([...changePlayer(do_add)])
    };

    const handleDiceRoll = () => {
        // Clear the probability table during rolling
        setProbabilities({})
        // Generate a random rolling time
        rollAnimTime = getNormallyDistributedRandomNumber(rollAnimTimeMean, rollAnimTimeSD)
        // Invokes the rollingCompleted useEffect
        setRollingCompleted(false)
    }

    const handleDoneFor = () => {
        // The player is done for, so we need to return a tile to the table after they had time to review the state
        const reviewTimeout = setTimeout(() => {
            if (stealTile(currentPlayer, tileTable, true)) {
                // Sort
                tileTable.tiles = tileTable.tiles.slice().sort((a, b) => a.value - b.value);
                // Remove highest
                transferRecord.flippedTile = tileTable.popFromCollection()
                // Handle cases where the tile that is put back is also the highest tile, and thus removed
                // These are not shown at all
                if (transferRecord.flippedTile &&
                    transferRecord.tile &&
                    transferRecord.flippedTile.value === transferRecord.tile.value
                ) {
                    transferRecord.flippedTile = undefined;
                }
                setPlayersState([...players])
            }
            endTurn();
        }, reviewTime);
        return () => clearTimeout(reviewTimeout);
    }

    const handleLangChance = (code: string) => {
        if (code !== langCode) {
            setLangCode(code)
        }
    }

    const handleDiePick = (i: number) => {
        const new_collection = dice.pickDie(i);
        setCurrentCollection([...new_collection]);
    };

    const endTurn = () => {
        const transferTimeout = setTimeout(() => { // Wait the animation to finish before ending the turn
            // Pick dice off table
            dice.reset_rolled();
            setRolledDice(dice.rolled)

            // Pick collection up
            dice.reset_collected();
            setCurrentCollection(dice.collected);

            // Switch to next player
            if (!maybeEndGame()) {
                // If the game has not ended, move to the next player
                let nextPlayerIndex = playersState.findIndex(player => player === currentPlayer) + 1;
                if (nextPlayerIndex >= players.length || !players[nextPlayerIndex].is_active) {
                    nextPlayerIndex = 0;
                }
                setCurrentPlayer(players[nextPlayerIndex]);
                setPlayersState([...players])
            }
        }, transferTime);
        return () => clearTimeout(transferTimeout); // Cleanup timeout
    }

    // R E T U R N

    return (
        <Layout do_sticky={false} do_footy={false}>
            <SEO
                title={text.title}
                description={text.description}
                image={preview.publicURL}
            />
            <PickominoLayout hideOverflow={inSlideState} maxWidth={800}
                             className={tutorial.finished() ? menuScreen.current : ""}
                             doOpaque={tutorial.finished()}>
                <div className={"lint"}>
                    {menuScreen.current != 'up' && <>
                        <PlayerHeader players={playersState}/>
                        <StyledDiceForm>
                            <div><h1
                                className={`shuffle ${tutorial.allowsPlay || tutorial.finished() ? "super-draw-attention" : ""} button${!dice.canRoll() ? " inactive" : ""}`}
                                onClick={dice.canRoll() && currentPlayer.is_human ? handleDiceRoll : undefined}>
                                <FaShuffle/>
                            </h1></div>
                            {[0, 1, 2, 3, 4, 5].map((v) => (
                                <DieComponent key={v} value={v} src={wormSvgFile}/>
                            ))}
                            <span className="tooltip">{text.collect}
                                {tutorial.Note({
                                        stage: ["collect", "choose", "risk"],
                                        va: 40
                                    })
                                    ?? (<span className="tooltip-text">{text.collectDesc}</span>)}
                        </span>
                            {currentCollection.map((value, index) => (
                                <h1 key={index}>{value > 0 ? value : " "}
                                    {index == 5 && tutorial.Note({
                                        stage: ["worm face"],
                                        ha: -20,
                                        va: -20,
                                        alignRight: true
                                    })}
                                </h1>
                            ))}
                            <span className="tooltip">{text.roll}
                                {(tutorial.Note({
                                        stage: ["roll", "ndice", "pick", "face", "pick2", "autoroll", "playround2"],
                                        va: 30
                                    }) ||
                                    tutorial.Note({
                                        stage: ["broke1", "broke2", "broke3"],
                                        va: 30,
                                        width: 300
                                    })) ?? (<span className="tooltip-text">{text.rollDesc}</span>)}
                        </span>
                            {rolledDice.map((value, index) => (
                                <h1 key={index} className={`
                            ${!rollingCompleted || !dice.isPickable(index) ? "inactive" : ""} 
                            ${rollingCompleted && index === targetDieIndex && !currentPlayer.is_human ? "bot-pick" : ""} 
                            ${rollingCompleted && turnOver() ? "no-option" : ""}
                        `}>{value > 0 ? value : " "}</h1>
                            ))}
                        </StyledDiceForm>
                        <TileTable table={tileTable}/>
                        <div className={`draw-attention button brain ${!tutorial.finished() ? "inactive" : ""}`}
                             onClick={() => toggleProbabilityTable()}>
                             {tutorial.Note({stage: ["brain", "appeal"], ha: -2, alignBottom: true})}🤖🧠
                        </div>
                    </>}
                    {gameOver.current != "ongoing" && <EndScreen players={players}/>}
                    {showPobas && <ProbabilityTable probabilities={probabilities} toTheAIstring={text.totheai}/>}
                    {menuScreen.current != "down" && <Menu/>}</div>
            </PickominoLayout>
        </Layout>
    );
};

export default PickominoPage;
