import React from "react"
import {PlayerOptions, TileValueMap, TutorialNoteProps, TutorialText} from "./interfaces";
import {getRandomFace} from "./methods";
import {StyledTutorialNote} from "./style";

const TileValue: TileValueMap = {
    21: 1, 22: 1, 23: 1, 24: 1, 25: 2, 26: 2, 27: 2, 28: 2,
    29: 3, 30: 3, 31: 3, 32: 3, 33: 4, 34: 4, 35: 4, 36: 4,
};

export class Tile {
    value: number;
    worth: number;


    constructor(value: number) {
        if (value < 21 || value > 36) {
            throw new Error("Tile value must be between 21 and 36");
        }
        this.value = value;
        this.worth = TileValue[value];
    }
}

export class Dice {

    private _empty = (): Array<number> => {
        return [0, 0, 0, 0, 0, 0];
    }

    // Define the values for each position
    private dieValues = [1, 2, 3, 4, 5, 5];

    public collected: Array<number>;
    public rolled: Array<number>;
    public lastPickedDie: number = -1;

    public reset_collected = () => {
        this.collected = this._empty();
        this.lastPickedDie = -1;
    }
    public reset_rolled = () => {
        this.rolled = this._empty();
    }

    constructor() {
        this.collected = this._empty();
        this.rolled = this._empty();
    }

    public isEmpty = (): boolean => {
        return this.collected.reduce((acc, curr) => acc + curr, 0) === 0;
    }

    public freeDice = (): boolean => {
        // Returns whether there are dice left to roll
        return this.collected.reduce((acc, curr) => acc + curr, 0) < 8;
    };

    public freeCollection = (): boolean => {
        // Returns whether there are free dice left to collect
        return  this.collected.some(collected => collected === 0)
    };

    public rolled_dice = (): boolean => {
        return this.rolled.reduce((total, currentValue) => total + currentValue, 0) > 0;
    }

    public canRoll = (): boolean => {
        // You can roll if there are dice left to roll,
        // and if there are no rolled dice on the table
        return this.freeDice() && !this.rolled_dice();
    }

    public rolling = () => {
        // Simulates the rolling of dice
        const remainingDice = 8 - this.collected.reduce((sum, value) => sum + value, 0);
        const rollOutcome = Array.from({length: remainingDice}, () => Math.floor(Math.random() * 6));
        const frequencies = rollOutcome.reduce((freq, value) => {
            freq[value]++;
            return freq;
        }, [0, 0, 0, 0, 0, 0]);
        return frequencies.map(count => Math.min(count, 5));
    }

    public rollDice = () => {
        // Simulates the landing of rolled dice
        this.rolled = this.rolling()
        return this.rolled;
    }

    public isPickable = (index: number): boolean => {
        // Returns weather a specific die can be picked up
        return (this.rolled[index] > 0 && this.collected[index] === 0);
    }

    public isBust = () => {
        // Can't go bust without dice rolled
        if (this.rolled.every(value => value === 0)) {
            return false; // Player is not busted
        }

        // Iterate over each die in rolled and collected arrays
        for (let i = 0; i < this.rolled.length; i++) {
            // Check if the die was rolled but not collected
            if (this.rolled[i] > 0 && this.collected[i] === 0) {
                return false; // Bust condition met
            }
        }
        return true;
    }

    public noOptions = (currentPlayer?: Player): boolean => {
        // Returns true if the current score is zero or if there are no tile remaining on the table
        // Also returns true of there are no tiles that can be picked up
        const score = this.score();
        if (!score || !tileTable.tiles.length) {
            return true;
        } else {
            // Check table tiles
            for (const tile of tileTable.tiles) {
                if (tile.value <= score) {
                    return false;
                }
            }
            // Check player tiles to steal
            for (const player of players) {
                // If the currentPlayer is provided, we can check prevent trying to steal your own tile.
                if (currentPlayer && currentPlayer === player) {
                    continue;
                }
                const lastTile = player.lastTile();
                if (lastTile && lastTile.value === score) {
                    return false;
                }
            }
        }
        return true
    }

    public pickDie = (index: number): Array<number> => {
        // if (this.isPickable(index)) {
        this.lastPickedDie = index;
        this.collected[index] = this.rolled[index];
        this.reset_rolled();
        return this.collected;
        // }
        // return undefined;
    }

    public score = (): number => {
        if (this.collected[this.collected.length - 1] === 0) {
            return 0;
        } else {
            // Calculate the total score by multiplying each collection value with its corresponding position value
            return this.collected.reduce((total, frequency, index) => {
                return total + frequency * this.dieValues[index];
            }, 0);
        }
    }

    public scoreString(): string | undefined {
        let scoreExplanation = '';
        // Calculate the total score by multiplying each collection value with its corresponding position value
        const totalScore = this.collected.reduce((total, frequency, index) => {
            if (frequency > 0) {
                const positionValue = this.dieValues[index];
                const positionScore = frequency * positionValue;
                if (scoreExplanation !== '') {
                    scoreExplanation += ' + ';
                }
                scoreExplanation += `(${frequency} × ${positionValue})`;
                return total + positionScore;
            }
            return total;
        }, 0);
        if (totalScore) {
            scoreExplanation += ` = ${totalScore}`;
            return scoreExplanation;
        } else {
            return undefined;
        }


    }
}

export class Player {
    get score(): () => number {
        return this._score;
    }

    private static uidCounter: number = 0;
    private static names: string[] = ["Alice", "Bob", "Charlie", "David", "Eve", "Frank", "Grace"];
    private static nextNameIndex: number = 0;
    public uid: number;
    public bot_name: string;
    public is_active: boolean;
    public name: string;
    public is_human: boolean = false;
    public face: string;
    public is_table: boolean;
    public tiles: Array<Tile> = [];

    public init_tiles = (tiles?: Array<Tile>) => {
        if (this.is_table) {
            this.tiles = Array.from({length: 16}, (_, index) => new Tile(index + 21));
        } else {
            this.tiles = tiles ?? []; // Initialize collection as an empty array
        }
    }

    public lastTile = (index: number = -1): Tile | undefined => {
        if (this.tiles.length === 0) {
            return undefined;
        }
        return this.tiles[this.tiles.length + index];
    }

    constructor(options: PlayerOptions = {}) {
        this.uid = Player.uidCounter++;
        this.is_table = options.is_table ?? false;
        if (this.is_table) {
            this.is_active = false;
            this.is_human = false;
            this.bot_name = "board";
        } else {
            this.is_active = options.is_active ?? true;
            this.is_human = options.is_human ?? false;
            this.bot_name = Player.names[Player.nextNameIndex++ % Player.names.length];
        }
        this.name = this.is_human ? 'You' : this.bot_name;
        this.face = this.is_human ? getRandomFace(2) : "🤖";
        this.init_tiles(options.tiles);
    }

    private _score = (): number => {
        let totalScore = 0;
        for (const tile of this.tiles) {
            totalScore += tile.worth;
        }
        return totalScore;
    }


    public toggleHuman = () => {
        // Toggle the human state of the player
        this.is_human = !this.is_human;
        // Depending on the new state give the player a new face
        this.face = this.is_human ? getRandomFace() : "🤖";
        // Return the face for state use
        return this.face;
    };

    public toggleActive = () => {
        this.is_active = !this.is_active;
        return this.is_active;
    }

    public addToCollection(tile: Tile | undefined): void { // Accept a Tile object instead of a number
        if (!tile) {
            return; // Exit early if tile is undefined
        }
        this.tiles.push(tile);
    }

    public popFromCollection(): Tile | undefined { // Return type changed to Tile | undefined
        const popped_tile = this.tiles.pop()
        return popped_tile;
    }

    public getTileIndex(tile: Tile | number): number | undefined {
        let value: number;
        if (typeof tile !== 'number' && 'value' in tile) { // Check if 'value' property exists on tile
            value = (tile as Tile).value;
        } else {
            value = tile as number;
        }
        const index = this.tiles.findIndex(t => t.value === value); // Compare whole objects
        return index === -1 ? undefined : index;
    }

    public removeFromCollection(tile: Tile): Tile | undefined {
        // Find the index of the tile with the specified value in the collection
        const index = this.getTileIndex(tile)

        // If the tile with the specified value is found, remove it from the collection and return it
        if (index !== undefined) {
            return this.tiles.splice(index, 1)[0];
        }
        // If the tile with the specified value is not found, return undefined
        return undefined;
    }
}

class TileTransferRecord {
    public tile: Tile | undefined;
    public flippedTile: Tile | undefined;
    public fromPlayer: Player | undefined;
    public toPlayer: Player | undefined;
    public shown: boolean = false;

    public set(from: Player, to: Player, tileObj: Tile) {
        this.fromPlayer = from;
        this.toPlayer = to;
        this.tile = tileObj;
    }

    public clear() {
        this.tile = undefined;
        this.fromPlayer = undefined;
        this.toPlayer = undefined;
        this.flippedTile = undefined;
    }
}


export const transferRecord = new TileTransferRecord();


export const allTiles = Array.from({length: 16}, (_, index) => new Tile(index + 21));


export const dice = new Dice();

export const tileTable = new Player({is_table: true})

export const players = [
    new Player(),
    new Player(),
    new Player(),
    new Player({is_active: false}),
    new Player({is_active: false}),
    new Player({is_active: false}),
];


class Tutorial {
    private text: TutorialText = {};
    public stageNumber: number = 0;
    public allowsPlay: boolean = false;

    set(text: TutorialText) {
        this.text = text;
    }

    public progress = (force: boolean = false, amount: number = 1): number => {
        if (!this.finished() && (force || this.stageNumber > 0)) {
            this.stageNumber += amount;
            if (this.stageNumber >= Object.keys(this.text).length) {
                this.finish()
            }
        }
        return this.stageNumber;
    }

    public addText = (stageName: string, addedText: string) => {
        this.text[stageName] += addedText
    }

    public changeAllowance = (newAllowState: boolean): boolean => {
        // Sets allowance state and returns whether allowance has changed
        if (this.allowsPlay != newAllowState) {
            this.allowsPlay = newAllowState;
            return true;
        } else {
            return false;
        }
    }

    private finish = () => {
        this.stageNumber = -1;
    }

    public finished = (): boolean => {
        return this.stageNumber === -1;
    }

    public started = (): boolean => {
        return this.stageNumber !== 0;
    }

    public isAt = (stage: string | number): boolean => {
        if (typeof stage === 'number') {
            return this.stageNumber === stage
        } else {
            return this.stageName() === stage;
        }
    }

    public stageName = (stageNumber?: number): string => {
        if (!stageNumber) {
            return Object.keys(this.text)[this.stageNumber];
        } else {
            return Object.keys(this.text)[stageNumber];
        }
    }

    public skip = (): number => {
        this.allowsPlay = true;
        this.finish();
        return this.stageNumber;
    }

    public Note = (props: TutorialNoteProps) => {
        if (!this.text) {
            return;
        }
        const isAtAny = props.stage.some((s) => this.isAt(s));
        if (!isAtAny) {
            return;
        }
        return (
            <StyledTutorialNote {...props}>
                <span dangerouslySetInnerHTML={{__html: this.text[this.stageName()]}}></span>
            </StyledTutorialNote>
        );
    };
}
export const tutorial = new Tutorial()
