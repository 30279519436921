// Duration of tile transfer animation (see CSS)
export const transferTime = 2000;

// Mean duration of dice roll
export const rollAnimTimeMean = 1500;

// SD of dice roll duration
export const rollAnimTimeSD = 300;

// The time a bot takes to decide on a tile pick
export const botDecideTime = 1500;

// Time that the bot waits to pick up a die
export const diePickWaitTime = 1500;

// The that the game waits if you go bust
export const reviewTime = 1500;

// Menu transition
export const menuTransitionTime = 1000;